<template>
    <div>
        <v-container>
            <v-card style="background-color: #F5F5F5" elevation="3">
                <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                    <span v-if="isNew">Nuevo</span>
                    <span v-if="!isNew">Editar</span>
                    estado de vacaciones
                </div>

                <v-form
                  ref="form"
                  v-model="isValid"
                  autocomplete="off"
                >
                <!-- FORMULARIO ESTADO -->
                <v-row>
                    <v-col class="px-7 mt-4" cols="6" sm="3" md="3" lg="3" xl="2">
                        <v-text-field
                            class="mb-n7"
                            outlined
                            dense
                            text
                            v-model="vacSta.VacStaCod"
                            label="Código">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="px-7 mt-2" cols="12" sm="9" md="6" lg="6" xl="5">
                        <v-text-field
                            class="mb-n7"
                            outlined
                            dense
                            text
                            v-model="vacSta.VacStaNom"
                            required
                            :rules="[v => !!v || 'El nombre es obligatorio']"
                            label="Nombre *">
                        </v-text-field>
                    </v-col>
                </v-row>

                <!-- VACSTADET -->
                <v-row class="mt-2">
                    <v-col class="px-7" cols="12" sm="12" md="12" lg="12" xl="12">
                        <!-- Boton añadir detalle -->
                        <v-btn
                          :loading="loading"
                          :disabled="editNewVacStaDet"
                          @click="onBtnNuevoVacStaDet"
                          color="primary"
                          small
                          class="mb-2">
                          Añadir detalle
                        </v-btn>

                        <v-data-table
                        :headers="vacStaDetHeaders"
                        :items="vacStaDetDataRows"
                        :options.sync="vacStaDetOptions"
                        :footer-props="{itemsPerPageOptions: [100]}"
                        :server-items-length="vacStaDetTotalDataRows"
                        :loading="vacStaDetLoading"
                        @click:row="onClickRowEditarVacStaDet"
                        style="overflow: scroll"
                        mobile-breakpoint="0"
                        class="elevation-1 vacStaDetDt"
                        dense>
                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                            <template v-slot:item.VacStaDetNom="{ item }">
                                <span v-if="!item.edit">{{item.VacStaDetNom}}</span>
                                <div v-else class="py-3" style="min-width: 275px; height: 100%">
                                    <v-text-field class="mb-n1" outlined dense text
                                        v-model="vacStaDetEditData.VacStaDetNom"
                                        label="Nombre"
                                        :hide-details="true">
                                    </v-text-field>
                                </div>
                            </template>
                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                            <template v-slot:item.VacStaDetObs="{ item }">
                                <span v-if="!item.edit">{{item.VacStaDetObs}}</span>
                                <div v-else class="py-3" style="min-width: 215px">
                                    <v-textarea
                                        class="mb-n1" outlined auto-grow hide-details
                                        v-model="vacStaDetEditData.VacStaDetObs"
                                        label="Observaciones">
                                    </v-textarea>
                                </div>
                            </template>
                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                            <template v-slot:item.Opt="{ item }">
                                <v-btn v-if="!item.edit"
                                    color="error"
                                    class="mr-2 my-1"
                                    small
                                    @click.stop="onBtnShowVacStaDetDeleteModal(item)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                                <div v-else class="py-1" style="min-width: 185px; height: 100%" align ="right">
                                    <v-btn
                                        :loading="vacStaDetLoading"
                                        :disabled="!isValidVacStaDet(vacStaDetEditData)"
                                        @click.stop="onBtnEditVacStaDet(item)"
                                        color="primary" class="mt-2 mb-4 mr-2" small>
                                        Aceptar
                                    </v-btn>
                                    
                                    <v-btn
                                        @click.stop="cancelVacStaDetEdit"
                                        class="mt-2 mb-4 mr-2" small>
                                        Cancelar
                                    </v-btn>
                                </div>
                            </template>
                            <!-- NUEVO DETALLE FORMULARIO -->
                            <template v-if="editNewVacStaDet" slot="body.append">
                                <tr class="new-row">
                                    <td>
                                        <div class="py-3" style="min-width: 275px; height: 100%">
                                            <v-text-field
                                                class="mb-n7" outlined dense text
                                                v-model="newVacStaDet.VacStaDetNom"
                                                label="Nombre">
                                            </v-text-field>
                                        </div>
                                    </td>
                                    <td>
                                        <div style="min-width: 215px" class="py-3">
                                            <v-textarea
                                                class="mb-n1" outlined auto-grow hide-details
                                                v-model="newVacStaDet.VacStaDetObs"
                                                label="Observaciones">
                                            </v-textarea>
                                        </div>
                                    </td>
                                    <td align="right">
                                        <div class="py-1" style="min-width: 185px; height: 100%">
                                            <v-btn
                                                :loading="vacStaDetLoading"
                                                :disabled="!isValidVacStaDet(newVacStaDet)"
                                                @click="onBtnSaveVacStaDet"
                                                color="primary" class="mt-2 mb-4 mr-2" small>
                                                Aceptar
                                            </v-btn>
                                            
                                            <v-btn
                                                @click="onBtnCancelVacStaDet"
                                                class="mt-2 mb-4 mr-2" small>
                                                Cancelar
                                            </v-btn>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                  
                <!-- ACEPTAR / CANCELAR / BORRAR -->
                <v-row class="mt-4" justify="center">
                    <v-btn
                        :loading="loading"
                        :disabled="!isValid"
                        @click="onBtnSave"
                        color="primary"
                        class="mt-2 mb-4 mr-2"
                        style="width:100px">
                        Aceptar
                    </v-btn>
                    
                    <v-btn
                        to="/estados-vacaciones"
                        class="mt-2 mb-4 mr-2"
                        style="width:100px">
                        Cancelar
                    </v-btn>

                    <v-btn v-if="!isNew"
                        color="error"
                        class="mt-2 mb-4"
                        style="width:100px"
                        @click="onBtnShowDeleteModal()">
                        Borrar
                    </v-btn>
                </v-row>
                </v-form>
            </v-card>
        </v-container>

        <!-- Modal de borrado -->
        <v-dialog
            v-model="showDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR ESTADO
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar el estado? Esta acción no se podrá deshacer.
                </v-card-text>

                <v-divider></v-divider>

              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      :loading="loading"
                      color="primary"
                      @click="onBtnDelete">
                      Aceptar
                  </v-btn>
                  <v-btn @click="onBtnCancelDelete">
                      Cancelar
                  </v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Borrar detalle -->
        <v-dialog
            v-model="showVacStaDetDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR DETALLE
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar el detalle?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="onBtnVacStaDetDelete">
                        Aceptar
                    </v-btn>
                    <v-btn @click="onBtnCancelVacStaDetDelete">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default ({
    // components: {},
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            tab: null,
            loading: false,
            isNew: true,
            isValid: false,
            showDeleteDialog: false,
            showVacStaDetDeleteDialog: false,
            vacSta: {
                VacStaId: 0,
                VacStaCod: '',
                VacStaNom: ''
            },
            // Datatable detalle
            vacStaDetLoading: false,
            vacStaDetTotalDataRows: 0,
            vacStaDetDataRows: [],
            vacStaDetOptions: {itemsPerPage: 100},
            vacStaDetHeaders: [
                { text: 'Nombre', width:200, sortable: false, value: 'VacStaDetNom' },
                { text: 'Observaciones', width:250, sortable: false, value: 'VacStaDetObs' },
                { text: '', width:100, sortable: false, value: 'Opt', align: 'end' }
            ],
            // Edición detalle
            editNewVacStaDet: false, // Indica si se esta editando el nuevo detalle
            newVacStaDet: {}, // Datos del nuevo detalle
            vacStaDetEditData: null, // Datos del detalle a editar
            selectedVacStaDet: null // Detalle seleccionado para borrar
        }
    },
    // watch: {
    //   vacSta: {
    //     handler () {
    //         this.validate();
    //     },
    //     deep: true,
    //   }
    // },
    mounted() {
      this.isNew = this.$route.meta.isNew;
      
      if(!this.isNew) {
        this.vacSta.VacStaId = this.$route.params.id;
        this.loadData();
      }
    },
    methods: {
      goToList() {
        this.$router.push('/estados-vacaciones');
      },

      //#region Llamadas a la API
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_VAC_STA',
            VacStaId: this.vacSta.VacStaId
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/vacsta", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.VacSta;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar el estado.');
                this.goToList();
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'SAVE_ENTITY',
            VacSta: this.vacSta,
            VacStaDets: this.vacStaDetDataRows
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/vacsta", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar el estado.');
            }
          });
        })
      },
      deleteDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'DEL_ENTITY',
            VacStaId: this.vacSta.VacStaId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/vacsta", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al borrar el estado.');
            }
          });
        })
      },
      //#endregion

      //#region Carga de datos
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert('Estado no encontrado.');
              this.goToList();
              return;  
            }

            this.vacSta = data.item;

            this.vacStaDetTotalDataRows = data.item.VacStaDet.length;
            this.vacStaDetDataRows = data.item.VacStaDet;
          });
      },
      //#endregion

      //#region  Añadir detalle
      onBtnNuevoVacStaDet() {
        this.editNewVacStaDet = true;
        this.newVacStaDet = { 
            VacStaDetNom: '', 
            VacStaDetObs: '', 
            edit: false 
        };
      },
      //#endregion

      //#region Click guardado
      onBtnSave() {
          this.validate();
          if(!this.isValid) { return; }
          this.postDataToApi().then(data => {
            if(data.item.VacStaId != null) {
                this.goToList();
            }
            else {
              alert('Error al guardar el estado.');
            }
          });
      },
      onBtnSaveVacStaDet() {
        if(!this.isValidVacStaDet(this.newVacStaDet)) { return; }
        
        this.vacStaDetDataRows.push(this.newVacStaDet);
        this.vacStaDetTotalDataRows += 1;

        this.newVacStaDet = {};
        this.editNewVacStaDet = false;
      },
      //#endregion

      //#region Editar detalles
      cancelVacStaDetEdit() {
        const editVacStaDets = this.vacStaDetDataRows.filter(f => f.edit);
        editVacStaDets.forEach(vacStaDet => {
          vacStaDet.edit = false;
        });
        
        this.vacStaDetEditData = null;
        this.$forceUpdate();
      },
      onClickRowEditarVacStaDet(item) {
        if(this.loading) { return; }
        if(item.edit) { return; }
        this.cancelVacStaDetEdit();
        this.vacStaDetEditData = {
          VacStaDetNom: item.VacStaDetNom,
          VacStaDetObs: item.VacStaDetObs
        };

        item.edit = true;
        this.$forceUpdate();
      },
      onBtnEditVacStaDet(dtData) {
        if(!this.isValidVacStaDet(this.vacStaDetEditData)) { return; }
        if(dtData != null) {
          dtData.VacStaDetNom = this.vacStaDetEditData.VacStaDetNom;
          dtData.VacStaDetObs = this.vacStaDetEditData.VacStaDetObs;

          this.cancelVacStaDetEdit();
        }
      },
      //#endregion

      //#region Click borrar
      onBtnDelete() {
        this.deleteDataFromApi().then(data => {
          if (data.item.Success) {
            this.showDeleteDialog = false;
            this.goToList();
          }
          else {
            alert(data.item.Message);
          }
        });
      },
      //#endregion

      //#region  Cancelar creación de detalle
      onBtnCancelVacStaDet() {
        this.editNewVacStaDet = false;
        this.newVacStaDet = {};
      },
      //#endregion

      //#region  Eliminar detalle
      removeVacStaDet(item) {
        var index = this.vacStaDetDataRows.indexOf(item);
        if (index != -1) {
          this.vacStaDetDataRows.splice(index, 1);
          this.vacStaDetTotalDataRows -= 1;
        }
      },
      onBtnVacStaDetDelete() {
        this.removeVacStaDet(this.selectedVacStaDet);
        this.selectedVacStaDet = null;
        this.showVacStaDetDeleteDialog = false;
      },
      //#endregion

      //#region Gestión de modales
      onBtnShowDeleteModal() {
        this.showDeleteDialog = true;
      },
      onBtnShowVacStaDetDeleteModal(item) {
        this.selectedVacStaDet = item;
        this.showVacStaDetDeleteDialog = true;
      },
      onBtnCancelDelete() {
        this.showDeleteDialog = false;
      },
      onBtnCancelVacStaDetDelete() {
        this.selectedVacStaDet = null;
        this.showVacStaDetDeleteDialog = false;
      },
      //#endregion
      

      //#region Validar formulario
      validate() {
          this.$refs.form.validate();
      },
      isValidVacStaDet(vacStaDet) {
        if(vacStaDet.VacStaDetNom == null || vacStaDet.VacStaDetNom == '') { return false; }
        return true;
      }
      //#endregion
    } 
})
</script>
